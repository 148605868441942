import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>O que nossos clientes estão dizendo</h2>
        </div>
        <div>
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  // className="col-md-4"
                  width={"300px"}
                  height={"200px"}
                  style={{
                    // border: "1px solid black",
                    margin: "10px",
                    backgroundColor: "#85bdb1",
                    borderRadius: "10px",
                  }}
                >
                  <div className="testimonial">
                    {/* <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div> */}
                    <div className="testimonial-content">
                      <p
                        style={{
                          color: "black",
                          marginBottom: "10px",
                        }}
                      >
                        ⭐⭐⭐⭐⭐
                      </p>
                      <p
                        style={{
                          color: "black",
                        }}
                      >
                        "{d.text}"
                      </p>
                      <div
                        className="testimonial-meta"
                        style={{
                          color: "black",
                        }}
                      >
                        {" "}
                        - {d.name}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
