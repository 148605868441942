import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div>
              <div className="col-md-8 col-md-offset-2 intro-text">
                <img
                  src="./img/icone.png"
                  alt="ícone saúde digital"
                  width={"300px"}
                  height={"300px"}
                  style={{ marginBottom: "50", marginTop: "-50PX" }}
                />
                <h1 className="title-page">
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="https://api.whatsapp.com/send/?phone=5511976767301&text&type=phone_number&app_absent=0"
                  className="btn btn-custom btn-lg page-scroll"
                  style={{
                    backgroundColor: "#009273",
                    backgroundImage: "none",
                  }}
                >
                  Preços a partir de R$59,90
                  {/* <br />
                  Preços a partir de R$59,90 */}
                </a>
                {/* <p>Preços a partir de R$59,90</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
